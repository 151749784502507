import { DocumentLoadInstrumentation } from '@opentelemetry/instrumentation-document-load';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
const initialIntrumentationsOptions = {
  ignoreUrls: [],
  propagateTraceHeaderCorsUrls: []
};
export function getDefaultOTELInstrumentations(options = initialIntrumentationsOptions) {
  return [new DocumentLoadInstrumentation(), new FetchInstrumentation(options), new XMLHttpRequestInstrumentation(options)];
}
