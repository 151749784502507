import { ESpanKind } from '@opentelemetry/otlp-transformer';
import { faro } from '@grafana/faro-core';
export function sendFaroEvents(resourceSpans = []) {
  var _a, _b;
  for (const resourceSpan of resourceSpans) {
    const {
      scopeSpans
    } = resourceSpan;
    for (const scopeSpan of scopeSpans) {
      const {
        scope,
        spans = []
      } = scopeSpan;
      for (const span of spans) {
        if (span.kind !== ESpanKind.SPAN_KIND_CLIENT) {
          continue;
        }
        const spanContext = {
          traceId: span.traceId.toString(),
          spanId: span.spanId.toString()
        };
        const faroEventAttributes = {};
        for (const attribute of span.attributes) {
          faroEventAttributes[attribute.key] = String(Object.values(attribute.value)[0]);
        }
        const index = ((_a = scope === null || scope === void 0 ? void 0 : scope.name) !== null && _a !== void 0 ? _a : '').indexOf('-');
        let eventName = 'unknown';
        if (scope === null || scope === void 0 ? void 0 : scope.name) {
          if (index === -1) {
            eventName = (_b = scope.name.split('/')[1]) !== null && _b !== void 0 ? _b : scope.name;
          }
          if (index > -1) {
            eventName = scope === null || scope === void 0 ? void 0 : scope.name.substring(index + 1);
          }
        }
        faro.api.pushEvent(`faro.tracing.${eventName}`, faroEventAttributes, undefined, {
          spanContext
        });
      }
    }
  }
}
