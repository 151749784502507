import { context, trace } from '@opentelemetry/api';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { Resource } from '@opentelemetry/resources';
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { BaseInstrumentation, VERSION } from '@grafana/faro-web-sdk';
import { FaroTraceExporter } from './faroTraceExporter';
import { getDefaultOTELInstrumentations } from './getDefaultOTELInstrumentations';
import { getSamplingDecision } from './sampler';
import { FaroSessionSpanProcessor } from './sessionSpanProcessor';
// the providing of app name here is not great
// should delay initialization and provide the full Faro config,
// taking app name from it
export class TracingInstrumentation extends BaseInstrumentation {
  constructor(options = {}) {
    super();
    this.options = options;
    this.name = '@grafana/faro-web-tracing';
    this.version = VERSION;
  }
  initialize() {
    var _a, _b, _c, _d, _e;
    const options = this.options;
    const attributes = {};
    if (this.config.app.name) {
      attributes[SemanticResourceAttributes.SERVICE_NAME] = this.config.app.name;
    }
    if (this.config.app.version) {
      attributes[SemanticResourceAttributes.SERVICE_VERSION] = this.config.app.version;
    }
    if (this.config.app.environment) {
      attributes[SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT] = this.config.app.environment;
    }
    Object.assign(attributes, options.resourceAttributes);
    const resource = Resource.default().merge(new Resource(attributes));
    const provider = new WebTracerProvider({
      resource,
      sampler: {
        shouldSample: () => {
          return {
            decision: getSamplingDecision(this.api.getSession())
          };
        }
      }
    });
    provider.addSpanProcessor((_a = options.spanProcessor) !== null && _a !== void 0 ? _a : new FaroSessionSpanProcessor(new BatchSpanProcessor(new FaroTraceExporter({
      api: this.api
    }), {
      scheduledDelayMillis: TracingInstrumentation.SCHEDULED_BATCH_DELAY_MS,
      maxExportBatchSize: 30
    }), this.metas));
    provider.register({
      propagator: (_b = options.propagator) !== null && _b !== void 0 ? _b : new W3CTraceContextPropagator(),
      contextManager: (_c = options.contextManager) !== null && _c !== void 0 ? _c : new ZoneContextManager()
    });
    registerInstrumentations({
      instrumentations: (_d = options.instrumentations) !== null && _d !== void 0 ? _d : getDefaultOTELInstrumentations({
        ignoreUrls: this.getIgnoreUrls(),
        propagateTraceHeaderCorsUrls: (_e = this.options.instrumentationOptions) === null || _e === void 0 ? void 0 : _e.propagateTraceHeaderCorsUrls
      })
    });
    this.api.initOTEL(trace, context);
  }
  getIgnoreUrls() {
    return this.transports.transports.flatMap(transport => transport.getIgnoreUrls());
  }
}
TracingInstrumentation.SCHEDULED_BATCH_DELAY_MS = 1000;
